import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/es/layout"
//import Image from "../components/image"
import SEO from "../../components/es/seo"

import MainImg from "./../../images/main_bg.jpg"

import shape1 from "./../../images/dec/1.png"
import shape2 from "./../../images/dec/2.png"
import shape3 from "./../../images/dec/3.png"
import shape4 from "./../../images/dec/4.png"
import shape5 from "./../../images/dec/5.png"
import shape6 from "./../../images/dec/6.png"
import shape7 from "./../../images/dec/7.png"
import shape8 from "./../../images/dec/8.png"

const Onama = () => (
  <Layout>
    <SEO
     title="MOONAMAR - SOBRE NOSOTROS Y NUESTRO TRABAJO" 
     description="MOONAMAR - para que la conciencia sobre las bendiciones de la naturaleza crezca y se encarne en nuestros productos."
     />

    <section
      id="_heroPage"
      className="hero is-large _o-nama"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">SOBRE NOSOTROS</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns">
              <div className="column _headline">
                <div className="content">
                  <p><strong>
                  Los productos de Moonamar están desarrolados con cuidado, amor y dedicación, bajo el lema de que los tesoros de la naturaleza tienen que ser cercanos y accesibles a todos. Aquí y ahora les presentamos el trabajo de veinte años en los campos de la medicina más actual y  de la natural, de la microbiología y de la bioquímica.
                  </strong></p>
                  <p>
                  Con la intensa colaboración de  nuestros numerosos colaboradores trabajamos para identificar aquellas sustancias naturales con los mayores beneficios para su bienestar y para poder presentárselas dentro del exclusivo catálogo de productos Moonamar.
                  </p>
                  <p>
                  Todos nuestros productos han sido elegidos y desarrollados dentro de una filosofía ecolócica y sostenible, así como dentro de las reglas de los alimentos “halal“, eso significa que  nuestros productos no contienen alcohol y en casi su totalidad sus componentes  no tienen un origen animal. En el caso de las cápsulas de aceite de comino negro, donde el envoltorio de éstas proviene de gelatina animal,  fue otorgado un certificado “halal“ para poder producirlas. 
                  </p>
                  <p>
                  Si desea alguno de nuestro productos, pero no lo puede encontrar en tiendas, o quieren más información sobre alguno de ellos, no dude en contactarnos.  Puede comprar nuestros productos en nuestra página web con nuestro “formulario para pedidos“.
                  </p>
                  <Link
                    to="/es/formulario-para-pedidos"
                    className="button is-primary is-rounded is-small btn1head"
                  >
                    PEDIDOS
                  </Link>
                  <br />
                  <br />
                  <p>
                  Para todas aquellaspreguntas que tenga puede también contactarnos telefónicamente  en el número
                   <a href="tel:+43 699 170 22 482"> +43 699 170 22 482</a>{" "} o por correo electrónico{" "}
                    <a href="mailto:support@moonamar.co">support@moonamar.co</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default Onama
